// auth
import './account';

// user activity
import './user-profile';

// app pages
import './mail';
import './calendar';
import './chat';
import './e-commerce';
import './customer';
import './contact';
import './cart';
import './kanban';
